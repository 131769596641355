import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "select-box-with-badge" }
const _hoisted_2 = {
  key: 0,
  class: "select-box__badge"
}
const _hoisted_3 = { class: "cursor-pointer position-relative z-index-1" }
const _hoisted_4 = {
  key: 1,
  class: "is-all-selected__text"
}
const _hoisted_5 = {
  key: 2,
  class: "custom-selection-label__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_SelectModal = _resolveComponent("SelectModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isBadgeVisible && _ctx.showBadge)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.modelValue.length), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["select-box-with-badge__input", {
                'is-all-selected': _ctx.isSelectedAll,
                'custom-selection-label': _ctx.customSelectionText,
                'hide-tags': _ctx.hideTags,
                'is-disabled': _ctx.isDisabled,
            }])
    }, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isDisabled)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = true)),
              class: "select-popup"
            }))
          : _createCommentVNode("", true),
        (_ctx.isSelectedAll)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.selectHeading.allSelectedText), 1))
          : (_ctx.modelValue.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.getCustomPlaceholder), 1))
            : _createCommentVNode("", true),
        _createVNode(_component_el_select, {
          multiple: _ctx.multiple,
          "collapse-tags": "",
          modelValue: _ctx.inputValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
          placeholder: _ctx.selectHeading.placeholder,
          disabled: _ctx.isDisabled
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: index,
                label: _ctx.selfValue ? item : (item.name || item.title),
                value: _ctx.selfValue ? item : item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["multiple", "modelValue", "placeholder", "disabled"])
      ])
    ], 2),
    (_ctx.dialogVisible)
      ? (_openBlock(), _createBlock(_component_SelectModal, {
          key: 1,
          "popup-visible": _ctx.dialogVisible,
          title: _ctx.selectHeading.modalTitle,
          subtitle: _ctx.selectHeading.modalSubtitle,
          placeholder: _ctx.selectHeading.modalPlaceholder,
          popupVisible: _ctx.dialogVisible,
          dataForSelect: _ctx.options,
          dataForUpdate: _ctx.inputValue,
          multiple: _ctx.multiple,
          selfValue: _ctx.selfValue,
          labelType: _ctx.labelType,
          "is-mandatory-select": _ctx.isMandatorySelect,
          onHandleClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialogVisible = false)),
          onHandleConfirm: _ctx.handleConfirm
        }, null, 8, ["popup-visible", "title", "subtitle", "placeholder", "popupVisible", "dataForSelect", "dataForUpdate", "multiple", "selfValue", "labelType", "is-mandatory-select", "onHandleConfirm"]))
      : _createCommentVNode("", true)
  ]))
}