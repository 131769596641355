
import SelectModal from "@/buying-teams/shared-components/modals/SelectModal.vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "SelectBoxWithModal",
    components: { SelectModal },
    props: {
        modelValue: {
            type: [Array, String],
            required: true
        },
        options: {
            type: Array,
            default: [],
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        selfValue: {
            type: Boolean,
            default: false
        },
        showBadge: {
            type: Boolean,
            default: false
        },
        placeholder: String,
        modalTitle: String,
        modalSubtitle: String,
        modalPlaceholder: String,
        allSelectedText: String,
        isDisabled: {
            type: Boolean,
            default: false
        },
        customSelectionText: {
            type: String,
            default: ''
        },
        hideTags: {
            type: Boolean,
            default: true
        },
        labelType: {
            type: String,
            default: null
        },
        isMandatorySelect: {
            type: Boolean,
            default: true
        },
    },
    emits: ["onTouch", "update:modelValue"],
    data() {
        return {
            inputValue: null as any,
            dialogVisible: false
        };
    },
    computed: {
        isSelectedAll() {
            if (!this.multiple) return false;
            return this.modelValue.length === this.options.length && this.options.length !== 0;
        },
        isBadgeVisible() {
            if (!this.multiple || !this.showBadge) return false;
            return this.modelValue.length > 0 && this.modelValue.length < this.options.length;
        },
        selectHeading() {
            const value: any = this.options[0];
            return {
                placeholder: this.placeholder ? this.placeholder : this.$t("Select"),
                modalTitle: this.modalTitle ? this.modalTitle : this.$t("Select"),
                modalSubtitle: this.modalSubtitle ? this.modalSubtitle : this.$t("Select"),
                modalPlaceholder: this.modalPlaceholder ? this.modalPlaceholder : this.$t("Search"),
                allSelectedText: this.isSelectedAll && this.options.length === 1
                    ? (this.selfValue ? value : (value.name || value.title))
                    : this.allSelectedText ? this.allSelectedText : this.$t("All")
            };
        },
        getCustomPlaceholder() {
            const currentElement: any = this.modelValue[0];
            let value: any = this.options.find((val: any) => val === currentElement || val.id === currentElement);
            value = this.selfValue ? value : (value.name || value.title);
            return this.modelValue.length === 1 ? value : this.customSelectionText;
        }
    },
    created() {
        this.inputValue = this.modelValue;
    },
    methods: {
        handleConfirm(event) {
            this.inputValue = event;
            this.$emit("update:modelValue", event);
            this.$emit("onTouch");
            this.dialogVisible = false;
        }
    },
    watch: {
        modelValue: {
            deep: true,
            handler(newVal) {
                this.inputValue = newVal;
            }
        }
    }
});
